<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container">
          <div class="mb-3">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/countries' }">Countries</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: `/${$route.params.countryName}/destinations/${$route.params.destinationId}` }">Destinations</el-breadcrumb-item>
              <el-breadcrumb-item>Packages</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

          <h5
            class="mb-4"
            style="font-size: 1.1em; font-weight: 600;"
          >{{$route.params.countryName}} - {{$route.params.destinationName}} - Packages:</h5>

          <div class="search_add_section">
            <div style="width: 50%;">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by package name"
                class="search_by_input"
                spellcheck="off"
                style="width: 100%;"
              />
            </div>
            <div>
              <AddDestinationPackage
                @refreshPackages="getPackages"
                :destinationId="$route.params.destinationId"
              />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading || deleting"
            class="table-responsive"
          >
            <data-tables
              :data="packages"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getPackages"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Packages Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No Packages</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="50px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Photo"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="80px"
              >
                <template slot-scope="scope">

                  <img
                    :src="scope.row.image"
                    style="width: 50px; height: 50px; object-fit: cover;"
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="Title"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span> <strong> </strong> {{ scope.row.title }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        @click="deletePackage(scope.row.id)"
                        type="danger"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import { format, parseISO } from "date-fns";
import AddDestinationPackage from "../components/dialogs/add-destination-package.vue";
export default {
  components: {
    AddDestinationPackage,
    ScaleOut,
  },

  data() {
    return {
      isLoading: false,
      loading: false,
      loadingError: false,
      deleting: false,
      isEditPackageDialogVisible: false,

      packages: [],
      destination: {},
      safariPackage: {},

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "room_name",
          value: "",
        },
      ],
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getPackages();
  },

  methods: {
    showEditPackageDialog(safariPackage) {
      this.isEditPackageDialogVisible = true;
      this.safariPackage = safariPackage;
    },

    closeEditPackageDialog() {
      this.isEditPackageDialogVisible = false;
    },

    async getPackages() {
      this.isLoading = true;
      this.loadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/packages/${this.$route.params.destinationId}`
        );
        if (request.data.success) {
          this.isLoading = false;
          this.packages = request.data.packages;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.loadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch packages now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deletePackage(id) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Package. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.post(
              `api/safari/destination/delete_packages`,
              {
                destination_id: this.$route.params.destinationId,
                packages: [`${id}`],
              }
            );
            if (
              request.data.success &&
              request.data.message === "DELETED_SUCCESSFULY"
            ) {
              this.getPackages();
              this.$notify({
                title: "Success",
                message: "Package deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete Package, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

input:focus {
  border: 0.01em solid #409eff !important;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>